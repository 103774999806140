<template>
  <div class="card" v-if="model">
    <form>
      <div class="card-header">
        <h4 class="card-title">Billing Form</h4>
      </div>
      <div class="card-body">
        <div class="row">

          <div class="form-group col-2">
            <label>Location ID</label>
            <fg-input
              name="location_id"
              v-model="model.location_id"
              v-validate="modelValidations.location_id"
              :error="getError('location_id')"
            >
              <el-select
                name="location_id"
                :class="!getError('location_id') ? 'select-default' : 'select-danger'"
                placeholder="Select Location"
                v-model="model.location_id"
                filterable
              >
                <el-option
                  v-for="(option, index) in locations"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.location_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-2">
            <label>Patient ID</label>
            <fg-input
              name="patient_id"
              v-model="model.patient_id"
              v-validate="modelValidations.patient_id"
              :error="getError('patient_id')"
            >
              <el-select
                name="patient_id"
                :class="!getError('patient_id') ? 'select-default' : 'select-danger'"
                class="select-default"
                placeholder="Select Patient"
                v-model="model.patient_id"
                filterable
              >
                <el-option
                  v-for="(option, index) in patients"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.patient_id} - ${option.name} - ${option.phone}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="form-group col-2">
            <label>Medication Start Date</label>
            <fg-input
              name="start_date"
              type="datetime"
              v-validate="modelValidations.start_date"
              v-model="model.start_date"
              :error="getError('start_date')"
            >
              <el-date-picker
                name="start_date"
                v-model="model.start_date"
                type="date"
                placeholder="Pick a Start Date"
              ></el-date-picker>
            </fg-input>
          </div>

          <div class="form-group col-2">
            <label>Number of Days</label>
            <fg-input 
            type="text" 
            name="days" 
            v-validate="modelValidations.days"
            v-model="model.days"
            :error="getError('days')">
            <el-input-number v-model="model.days" :min="1"></el-input-number>
            </fg-input>
          </div>

          <div class="form-group col-2">
            <label for>GST Type</label>
            <fg-input 
            type="text" 
            name="GST_type" 
            v-validate="modelValidations.GST_type"
            v-model="model.GST_type"
            :error="getError('GST_type')">
                <el-select
                name="GST_type"
                class="select-default"
                placeholder="Select Type"
                :class="!getError('GST_type') ? 'select-default' : 'select-danger'"
                v-model="model.GST_type">
                <el-option
                    v-for="(option, index) in gstTypes"
                    class="select-success"
                    :value="option"
                    :label="`${option}`"
                    :key="index"
                ></el-option>
                </el-select>
            </fg-input>
          </div>

          <div class="form-group col-2">
            <label>GSTIN</label>
            <fg-input type="text" name="gstin" v-model="model.gstin" v-validate="modelValidations.gstin" :error="getError('gstin')"></fg-input>
          </div>

          <div class="form-group col-4">
            <label>Delivery Note</label>
            <fg-input type="text" name="delivery_note" v-model="model.delivery_note" v-validate="modelValidations.delivery_note" :error="getError('delivery_note')"></fg-input>
          </div>

          <div class="form-group col-2">
            <label>Shipping Charges</label>
            <fg-input type="number" name="shipping_charges" v-model="model.shipping_charges" v-validate="modelValidations.shipping_charges" :error="getError('shipping_charges')"></fg-input>
          </div>

          <div class="form-group col-2">
            <label>Consultation Charge</label>
            <fg-input type="number" name="consultation_charge" v-model="model.consultation_charge" v-validate="modelValidations.consultation_charge" :error="getError('consultation_charge')"></fg-input>
          </div>

          <div class="form-group col-4">
            <label>Is Estimate ?</label>
            <fg-input type="number" name="isEstimate" v-model="model.isEstimate.toString()" v-validate="modelValidations.isEstimate" :error="getError('isEstimate')">
              <p-switch v-model="model.isEstimate" on-text="Yes" off-text="No"></p-switch>
            </fg-input>
          </div>

          <div class="form-group col-4" v-if="false">
            <label for>Payment Mode</label>
            <fg-input 
            type="text" 
            name="mode" 
            v-validate="modelValidations.mode"
            v-model="model.mode"
            :error="getError('mode')">
                <el-select
                name="mode"
                class="select-default"
                placeholder="Select Type"
                v-model="model.mode"
                :class="!getError('mode') ? 'select-default' : 'select-danger'">
                <el-option
                    v-for="(option, index) in modes"
                    class="select-success"
                    :value="option.id"
                    :label="`${option.name}`"
                    :key="index"
                ></el-option>
                </el-select>
            </fg-input>
          </div>
          
          <div class="form-group col-4" v-if="false">
            <label>Payment ID</label>
            <fg-input type="text" name="payment_id" v-model="model.payment_id" v-validate="modelValidations.payment_id" :error="getError('payment_id')"></fg-input>
          </div>


        </div>
        <hr>

        <el-table
          style="width: 100%"
          :data="items"
          height="300"
          show-summary
          sum-text="Totals"
          :summary-method="getSummaries"
        >
          <el-table-column :min-width="min_width" type="index"></el-table-column>

          <el-table-column :min-width="250" label="Product" align="center">
            <div slot-scope="{row}" class="input-container">
             
                <el-select
                  name="comment"
                  class="select-default"
                  placeholder="Select Product"
                  v-model="row.product_id"
                  filterable
                  @change="updateProduct(row)"
                >
                  <el-option
                    v-for="(option, index) in products"
                    class="select-default"
                    :value="option.id"
                    :label="`${option.product_id} - ${option.name}`"
                    :key="index"
                  ></el-option>
                </el-select>

            </div>
          </el-table-column>

          <!-- <el-table-column :min-width="100" label="ID" align="center" prop="product.product_id"></el-table-column> -->
          <!-- <el-table-column :min-width="100" label="Name" align="left" prop="product.name"></el-table-column> -->
          <el-table-column :min-width="100" label="Type" align="left" prop="product.product_type.name"></el-table-column>
          <el-table-column :min-width="100" label="U.Qty" align="center" prop="product.quantity"></el-table-column>

          <!-- <el-table-column :min-width="100" label="Rate" align="center">
            <div slot-scope="{row}" class="td-number">{{ `Rs. ${Math.round(row.product.purchase_price)}` }}</div>
          </el-table-column> -->

          <!-- <el-table-column :min-width="100" label="GST" align="center">
            <div slot-scope="{row}" class="td-number">{{ `Rs. ${Math.round(row.product.purchase_price * (row.product.gst_type.percentage/100))}` }}</div>
          </el-table-column> -->

          <el-table-column :min-width="150" label="Quantity" align="center">
            <div slot-scope="{row}" class="td-number">
              <!-- <fg-input type="text" name="quantity" v-model="row.quantity"></fg-input> -->
              <div class="btn-group">
                <p-button type="default" size="sm" @click.native="decreaseQuantity(row)" outline>
                  <i class="nc-icon nc-simple-delete"></i>
                </p-button>
                <p-button type="default" size="sm">{{ row.quantity }}</p-button>
                <p-button type="default" size="sm" @click.native="increaseQuantity(row)" outline>
                  <i class="nc-icon nc-simple-add"></i>
                </p-button>
              </div>
            </div>
          </el-table-column>

          <el-table-column :min-width="100" label="Price" align="center">
            <div slot-scope="{row}" class="td-number">{{ `Rs. ${Math.round(row.product.sale_price)}` }}</div>
          </el-table-column>

          <el-table-column :min-width="100" label="GST %" align="center">
            <div slot-scope="{row}" class="td-number">{{ `${row.product.gst_type.percentage} %` }}</div>
          </el-table-column>

          

          <el-table-column :min-width="150" label="Discount" align="center">
            <div slot-scope="{row}" class="input-container">
              <el-select
                name="comment"
                class="select-default"
                placeholder="Select Discount"
                v-model="row.discount"
                filterable
                @change="computeAmount(row)"
                value-key="id"
              >
                <el-option
                  v-for="(option, index) in discounts"
                  class="select-default"
                  :value="option.id"
                  :label="`${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </el-table-column>

          <el-table-column :min-width="150" label="Disc. Amount" align="center">
            <div slot-scope="{row}" class="td-number">{{ `Rs. ${row.discount_amount}` }}</div>
          </el-table-column>

          <el-table-column :min-width="100" label="Amount" align="center">
            <div slot-scope="{row}" class="td-number">{{ `Rs. ${computeAmount(row)}` }}</div>
          </el-table-column>

          <el-table-column min-width="150" label>
            <div slot-scope="props" class="td-actions">
              <p-button type="danger" size="sm" simple icon outline round @click="removeProductItem(props.$index)">
                <i class="fa fa-trash"></i>
              </p-button>
              <p-button type="info" size="sm" simple icon outline round @click="addProductItem()">
                <i class="fa fa-plus"></i>
              </p-button>
            </div>
          </el-table-column>

        </el-table>
      </div>
      <div class="card-footer text-right">
        <p-button type="success" @click.prevent="validate">Save Bill</p-button>
      </div>
    </form>
  </div>
</template>
<script>
import {
  Upload,
  DatePicker,
  Select,
  Option,
  Table,
  TableColumn,
  InputNumber
} from "element-ui";
import { Switch } from "src/components/UIComponents";
import { mapGetters } from "vuex";
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [InputNumber.name]: InputNumber,
    [Switch.name]: Switch
  },
  mounted() {
    // this.$store.dispatch("fetchPrescription", 1);
    this.$store.dispatch("fetchLocationsAll");
    this.$store.dispatch("fetchSimplePatientsAll");
    this.$store.dispatch("fetchProductsAll");
    this.$store.dispatch("fetchDiscountsAll");
    this.$store.dispatch("fetchModesAll");
    setTimeout(()=>{this.min_width = 50}, 1000);
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      discounts: "getDiscounts",
      modes: "getModes",
      locations: "getLocations",
      patients: "getPatients",
      products: "getProducts",
      items: "getProductItems",
    }),
    total() {
      const values = this.items.map(item =>
        parseInt(item.product.sale_price) * item.quantity
      );
      return values.reduce((prev, curr) => {
        const value = Number(curr);
        if (!isNaN(value)) {
          return prev + curr;
        } else {
          return prev;
        }
      }, 0);
    },
    discount() {
      const values = this.items.map(item => {
        let discount = this.discounts.find( discount => discount.id == item.discount );
        let value = 0;
        console.log('dis', discount);
        switch (discount.type) {
          case "reduction":
            value = Math.floor(item.quantity * parseInt(discount.value));
            break;
          case "percent":
            value = Math.floor(item.quantity * item.product.sale_price * (parseInt(discount.value) / 100));
            break;
          default:
            value = 0;
        }
        return value;
      });
      return values.reduce((prev, curr) => {
        const value = Number(curr);
        if (!isNaN(value)) {
          return prev + curr;
        } else {
          return prev;
        }
      }, 0);
    },
    amount() {
      const values = this.items.map(item => item.amount);
      return values.reduce((prev, curr) => {
        const value = Number(curr);
        if (!isNaN(value)) {
          return prev + curr;
        } else {
          return prev;
        }
      }, 0);
    },
    gst_amount() {
      const values = this.items.map(item => {

          return Math.ceil(item.amount / (100 + item.product.gst_type.percentage) * item.product.gst_type.percentage);

          // let discount = this.discounts.find(
          //   discount => discount.id == item.discount
          // );
          // let value = 0;
          // switch (discount.type) {
          //   case "reduction":
          //     value = parseInt(discount.value);
          //     break;
          //   case "percent":
          //     value = Math.floor(
          //       item.product.sale_price * (parseInt(discount.value) / 100)
          //     );
          //     break;
          //   default:
          //     value = 0;
          // }
          // let discounted_price = parseInt(item.product.sale_price) - value
          // return Math.ceil(discounted_price * (item.product.gst_type.percentage/100)) * item.quantity
        });
      return values.reduce((prev, curr) => {
        const value = Number(curr);
        if (!isNaN(value)) {
          return prev + curr;
        } else {
          return prev;
        }
      }, 0);
    }
  },
  data() {
    return {
      current_patient: null,
      modelValidations: {
        patient_id: {
          required: true
        },
        location_id: {
          required: true
        },
        ref_name: {
          required: false
        },
        ref_number: {
          required: false
        },
        diagnosis: {
          required: true
        },
        note: {
          required: false
        },
        date: {
          required: true
        },
        days: {
          required: true
        },
        start_date: {
          required: true
        },
        GST_type: {
          required: true
        },
        delivery_note: {
          required: true
        },
        // mode: {
        //   required: true
        // },
        // payment_id: {
        //   required: true
        // }
        isEstimate: {
          required: true
        },
        shipping_charges: {
          required: true
        },
        gstin: {
          required: false
        }
        
      },
      gstTypes: [
        "CSGST",
        "IGST"
      ],
      min_width: 49,
      model: {
          location_id: null,
          patient_id: null,
          start_date: null,
          days: 1,
          GST_type: null,
          shipping_charges: 0,
          consultation_charge: 0,
          isEstimate: false,
          delivery_note: null,
          gstin: null
      }
    };
  },
  methods: {
    
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        this.$emit("on-submit", this.registerForm, isValid);
        if (isValid) {
          this.model.employee_id = this.currentUser.profile_id;
          this.model.total = this.total;
          this.model.amount = this.amount;
          this.model.discount = this.discount;
          this.model.GST_amount = this.gst_amount;
          this.model.items = this.items;
          // console.log(this.model);
          // this.model.items = this.items
          this.$store.dispatch('createBillingNew', this.model)
        }
      });
    },
    increaseQuantity(row) {
      row.quantity++;
      this.computeAmount(row);
    },
    decreaseQuantity(row) {
      if (row.quantity > 0) {
          row.quantity--;
        this.computeAmount(row);
      }
    },
    updateProduct(row){
      row.product = this.products.find(
        product => product.id == row.product_id
      );
      this.computeAmount(row)
    },
    computeAmount(row) {
      let discount = this.discounts.find(
        discount => discount.id == row.discount
      );
      let discount_amount = 0
      if(discount){
        console.log('discount', discount);
        switch (discount.type) {
          case "reduction":
            discount_amount = parseInt(discount.value)
            // row.amount = row.quantity * row.product.sale_price - parseInt(discount.value);
            break;
          case "percent":
            discount_amount = Math.round(row.product.sale_price * (parseInt(discount.value) / 100))
            // row.amount = Math.ceil(row.quantity * row.product.sale_price - row.quantity * row.product.sale_price * (parseInt(discount.value) / 100));
            break;
          default:
            row.amount = row.quantity * row.product.sale_price;
        }
        row.amount = Math.ceil(row.quantity * (row.product.sale_price - discount_amount));
        row.discount_amount = Math.ceil(row.quantity * discount_amount)
      }
      else{
        row.amount = 0
        row.discount_amount = 0
      }

      return row.amount;
    },
    addProductItem(){
        this.$store.commit("addProductItem");
    },
    removeProductItem(index) {
      this.$store.commit("removeProductItem", index);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "=";
          return;
        }

        // if (index === 4) {
        //   const values = data.map(item => Math.round((item.product.purchase_price) * item.quantity));
        //   sums[index] = values.reduce((prev, curr) => {
        //     const value = Number(curr);
        //     if (!isNaN(value)) {
        //       return prev + curr;
        //     } else {
        //       return prev;
        //     }
        //   }, 0);
        // }
        
        
        // if (index === 9) {
        //   const values = data.map(item => Math.ceil(item.product.sale_price * (item.product.gst_type.percentage/100)) * item.quantity);
        //   sums[index] = values.reduce((prev, curr) => {
        //     const value = Number(curr);
        //     if (!isNaN(value)) {
        //       return prev + curr;
        //     } else {
        //       return prev;
        //     }
        //   }, 0);
        // }
        
        
        if (index === 5) {
          const values = data.map(item => parseInt(item.product.sale_price) * item.quantity);
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }

        if (index === 8) {
          const values = data.map(item => item.discount_amount);
          // console.log(values)
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }

        if (index === 9) {
          const values = data.map(item => item.amount);
          // console.log(values)
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });

      // sums[5] = 'Rs. ' + Math.abs(sums[7] - sums[4]);
      // sums[4] = "Rs. " + sums[4];
      // sums[10] = "Rs. " + sums[10];
      sums[5] = "Rs. " + sums[5];
      sums[8] = "Rs. " + sums[8];
      sums[9] = "Rs. " + sums[9];
      return sums;
    },
  }
};
</script>
<style lang="scss">

  .el-input-number .el-input__inner{
    outline: none;
  }

  .btn-primary, .form-group .el-input-number .plus-button, .form-group .el-input-number .el-input-number__decrease, .form-group .el-input-number .el-input-number__increase{
    background-color: #66615B;
    color: #fff;
  }

  .form-group .el-input-number .el-input-number__increase{
    border-radius: 0 5px 5px 0 !important;
  }

  .form-group .el-input-number .el-input-number__decrease{
    border-radius: 5px 0px 0px 5px !important;
  }

  .select-default.el-select .el-input input{
    border-radius: 5px;
  }

  .form-group .el-input-number{
    width: 100%!important;
  }
</style>
